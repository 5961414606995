<template>
  <div class="gateway">
    <h2 class="gateway-title">Your Gateway to the Function X Ecosystem</h2>
    <ul class="gateway-list">
      <li class="gateway-list-item" v-for="item in list" :key="item.title">
        <div class="gateway-list-icon"><img :src="require(`@/assets/images/wallet/${item.icon}`)" alt="" /></div>
        <h3 class="gateway-list-title">{{ item.title }}</h3>
        <div class="gateway-list-note common-text">{{ item.note }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Gateway',
  data() {
    return {
      list: [
        {
          icon: 'wallet_03_f(x)bridge.png',
          title: 'f(x)Bridge',
          note:
            'Send your funds to f(x)Core with the tap of a button. A decentralized cross-chain bridge that offers one of the largest selections of tradable cryptocurrencies. Utilizing Gravity Bridge’s technology to allow you to bridge tokens safely across and between networks.'
        },
        {
          icon: 'wallet_03_decentralized.png',
          title: 'Decentralized Governance',
          note:
            'Participate in the decision-making process and get involved with the future developments of Function X by creating and voting for proposals with FX coins to improve f(x)Core’s efficiency, usability, and global reach.'
        },
        {
          icon: 'wallet_03_safeguard.png',
          title: 'Safeguard the network',
          note:
            'Holders of FX or PUNDIX coins can safeguard the network and validate transactions by delegating their coins to hosted validators of the network and in return, stakeholders will receive voting rights and block rewards proportional to their delegated amount.'
        },
        {
          icon: 'wallet_03_multi.png',
          title: 'Multi-Faceted',
          note:
            'A multi-faceted blockchain wallet with a variety of distinctive and defining elements that makes it unique from other mobile wallets.'
        },
        {
          icon: 'wallet_03_marginx.png',
          title: 'MarginX Chain: A Decentralized Derivative Exchange',
          note:
            'Integrated with MarginX, the World’s First community-based decentralized exchange, traders can now monitor their positions and check their balances on the go, wherever they are.'
        },
        {
          icon: 'wallet_03_pundix.png',
          title: 'Pundi X Chain: A dedicated payment-focused blockchain',
          note:
            'Merchants with XPOS, the World’s First Web3 Point-Of-Sales blockchain-based device developed by Pundi X, can utilize Pundi Wallet by linking with p(x)Change dApp to accept cryptocurrencies as payment. Through p(x)Change, customers can top up their Pundi Wallet with cryptocurrencies.'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.gateway {
  width: var(--content-width);
  padding: 156px 32px;
  margin-left: auto;
  margin-right: auto;
  .gateway-title {
    max-width: 720px;
  }
  .gateway-list {
    margin-top: 80px;
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    grid-column-gap: 112px;
    grid-row-gap: 80px;
  }
  .gateway-list-item {
  }
  .gateway-list-icon {
    width: 112px;
  }
  .gateway-list-title {
    margin-top: 16px;
  }
  .gateway-list-note {
    margin-top: 8px;
  }
}
</style>
